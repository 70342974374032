import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/viewmodels/login.viewmodel';
import { Router } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginObject:Login = {username:'',password:''};
  errorMessage:boolean = false;

  constructor(private router:Router) { }

  ngOnInit() {
  }

  // callSignIn()
  // {
  //   // let result = this.authService.signInUser(this.loginObject);
  //   // if(result.status)
  //   // {
  //   //   localStorage.setItem('token',result.token);
  //   //   this.router.navigate(['/']);
  //   //   this.errorMessage = false;
  //   // }
  //   // else
  //   // {
  //   //   this.router.navigate(['/login']);
  //   //   this.errorMessage = true;
  //   // }
  // }
}
