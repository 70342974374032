import { Component, OnInit } from '@angular/core';
import { EquipmentDetails } from 'src/app/viewmodels/equipmentdetails.viewmodel';
import { NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-buy-sale-rent',
  templateUrl: './buy-sale-rent.component.html',
  styleUrls: ['./buy-sale-rent.component.css']
})

export class BuySaleRentComponent implements OnInit {

  equipmentDetails: EquipmentDetails;
  
  constructor(private fireStore: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm( form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.equipmentDetails = {
    username: '',
    equipmentcategory: '',
    category: false,
    make: '',
    manifacturingyear: '',
    location: '',
    price: '',
    machineage: '',
    evaluate: false,
    dateofposting: new Date(),
    };
  }

 
  onSubmit(form: NgForm) {
      let data = form.value;
      if(this.equipmentDetails.category)
        this.fireStore.collection('Sale-Equipments').add(this.equipmentDetails);
      else
        this.fireStore.collection('Rent-Equipments').add(this.equipmentDetails);

      this.resetForm();
      this.toastr.success('Equipment listed Successfully', 'Alert');
  }
}
