import { Component, OnInit } from '@angular/core';
import { ContactDetails } from 'src/app/viewmodels/contactus.viewmodel';
import { NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactDetais: ContactDetails;
  constructor(private fireStore: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }
  resetForm( form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.contactDetais = {
      username: '',
      emailid: '',
      subject: '',
      message: '',
      dateofposting: new Date()
    };
  }

 
  onSubmit(form: NgForm) {
      let data = form.value;
      this.fireStore.collection('ContactUs').add(this.contactDetais);
      this.resetForm();
      this.toastr.success('Thank You for Contacting Us, We Will Get Back to You', 'Alert');
  }

}
