import { Component, OnInit } from '@angular/core';
import { Evaluation } from 'src/app/viewmodels/evaluation.viewmodel';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

  evaluation: Evaluation;
  constructor(private fireStore: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm( form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.evaluation = {
      make: '',
      equipmentname: '',
      location: '',
      username: '',
      phonenumber: '',
      dateofposting: new Date()
    };
  }
 
  onSubmit(form: NgForm) {
      let data = form.value;
      this.fireStore.collection('EvaluationRequests').add(this.evaluation);
      this.resetForm();
      this.toastr.success('Evaluation requested Successfully', 'Alert');
  }
}
