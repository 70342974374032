import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { TechSupport } from 'src/app/viewmodels/techsupport.viewmodel';


@Component({
  selector: 'app-technical-suport',
  templateUrl: './technical-suport.component.html',
  styleUrls: ['./technical-suport.component.css']
})
export class TechnicalSuportComponent implements OnInit {

  techSupport: TechSupport;
  constructor(private fireStore: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm( form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.techSupport = {
      category: '',
      requirement: '',
      location: '',
      username: '',
      phonenumber: '',
      dateofposting: new Date()
    };
  }
 
  onSubmit(form: NgForm) {
      let data = form.value;

      if(this.techSupport.category){
         this.techSupport.category = 'Mechanical'; 
      }
      else{
        this.techSupport.category = 'Electrical'; 
      }

      this.fireStore.collection('TechnicalSupportRequests').add(this.techSupport);
      this.resetForm();
      this.toastr.success('Technical support requested Successfully', 'Alert');
  }
}
