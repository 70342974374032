import { Injectable } from '@angular/core';
import { UserDetails } from '../viewmodels/userdetails.viewmodel';

@Injectable({
  providedIn: 'root'
})
export class RegisteruserService {

  formData: UserDetails ;
  constructor() { }
}
