import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { BuySaleRentComponent } from './components/buy-sale-rent/buy-sale-rent.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { JobPostComponent } from './components/job-post/job-post.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatButtonModule, MatCheckboxModule} from '@angular/material';

import { environment } from 'src/environments/environment';
import { RegisteruserService } from './services/registeruser.service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { OtpComponent } from './components/otp/otp.component';
import { WindowService } from './services/window.service';
import { JobSearchComponent } from './components/job-search/job-search.component';
import { TechnicalSuportComponent } from './components/technical-suport/technical-suport.component';
import { EqupmentAllComponent } from './components/equpment-all/equpment-all.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'login', component: LoginComponent},
  {path: 'otp', component: OtpComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'rent-sale', component: BuySaleRentComponent},
  {path: 'evaluation', component: EvaluationComponent},
  {path: 'job-post', component: JobPostComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'job-search', component: JobSearchComponent},
  {path: 'technical-suport', component: TechnicalSuportComponent},
  {path: 'equpment-all', component: EqupmentAllComponent},
  {path: 'my-profile', component: MyProfileComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    ServicesComponent,
    LoginComponent,
    RegisterComponent,
    NotFoundComponent,
    FooterComponent,
    BuySaleRentComponent,
    ProfileComponent,
    EvaluationComponent,
    JobPostComponent,
    OtpComponent,
    JobSearchComponent,
    TechnicalSuportComponent,
    EqupmentAllComponent,
    MyProfileComponent
  ],
  imports: [
    BrowserModule, RouterModule.forRoot(appRoutes), HttpClientModule, FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule, AngularFirestoreModule,
    CommonModule,
    AngularFireAuthModule,
    MatButtonModule, MatCheckboxModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [RegisteruserService, WindowService],
  bootstrap: [AppComponent]
})
export class AppModule { }
