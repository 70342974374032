import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { JobDetails } from 'src/app/viewmodels/jobdetails.viewmodel';

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.css']
})
export class JobPostComponent implements OnInit {

jobDetails: JobDetails;

  constructor(private fireStore: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm( form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.jobDetails = {
      companyname: '',
      jobcategory: '',
      jobtitle: '',
      jobdescription: '',
      salary: '',
      openings: '',
      skillset: '',
      joblocation: '',
      hrname: '',
      phonenumber: '',
      validtill: new Date(new Date().getDate() + 30),
      dateofposting: new Date()
    };
  }

 
  onSubmit(form: NgForm) {
      let data = form.value;
      this.fireStore.collection('Jobs').add(this.jobDetails);
      this.resetForm();
      this.toastr.success('Job posted Successfully', 'Alert');
  }
}
