import { Component, OnInit } from '@angular/core';
import { RegisteruserService } from 'src/app/services/registeruser.service';
import { NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(public service: RegisteruserService, private fireStore: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm( form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.service.formData = {
      username: '',
      password: '',
      mobile: '',
      emailid: '',
      category: '',
      city: '',
      dateofposting: new Date()
    };
  }


  onSubmit(form: NgForm) {
      this.fireStore.collection('UserDetails').add(this.service.formData);
      this.resetForm();
      this.toastr.success('User Registered Successfully', 'Alert');
  }
}
